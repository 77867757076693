<template>
  <div class="quote-created-by-sidebar">
    <div class="quote-created-by-sidebar--content">
      <v-form ref="form">
        <AutoCompleteUser
          label="Update Sent By User"
          placeholder="Select User"
          :initial-user="selectedUser"
          @user-selected="userSelected"
        />
      </v-form>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        id="quote-created-by-save-btn"
        :loading="submitting"
        class="quote-created-by-sidebar--action-btn"
        color="primary"
        @click="submit"
      >
        Save
      </CRButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'
import AutoCompleteUser from '@/components/AutoCompleteUser.vue'

export default {
  components: {
    AutoCompleteUser,
  },
  props: {
    quoteId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      quote: {},
      users: [],
      selectedUser: this.quote?.createdById || null,
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const quoteResult = await this.$store.dispatch(
      'quotes/getV2Quote',
      this.quoteId
    )
    this.quote = quoteResult.data.quote
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    userSelected(selectedUser) {
      this.selectedUser = selectedUser.userId
    },
    async submit() {
      const payload = {
        quoteId: this.quote.quoteId,
        createdById: this.selectedUser,
      }
      try {
        const res = await this.$store.dispatch(
          'quotes/updateQuoteSentBy',
          payload
        )
        EventBus.$emit('refreshQuote')
      } catch (e) {
        this.showAlert({
          type: 'error',
          message: 'Error creating vanity url.',
        })
      }
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.quote-created-by-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}

::v-deep .accent--text {
  color: $blue !important;
}
</style>
