import { mapActions, mapGetters, mapState } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentSession?.user,
    currentUserProfile: (state) => state.currentSession?.userProfile,
  }),
  ...mapGetters('auth', [
    'loggedIn',
    'isBroker',
    'isSDR',
    'isRA',
    'isSDRManager',
    'isRAManager',
    'isL1Manager',
    'isSeniorISR',
    'isMmeSales',
    'isBrokerAdmin',
    'isBrokerUser',
    'isSuperAdmin',
    'isFlexUser',
    'hasManagerOverride',
    'hasCallCenter',
    'canModifyUserRoles',
    'canViewProviderCallerId',
    'canViewFeatureToggles',
    'hasPermission',
    'isKnownAddressEditor',
  ]),
}

export const callCenterComputed = {
  ...mapState('callCenter', {
    currentCallCenter: (state) => state.callCenter,
  }),
  ...mapGetters('callCenter', [
    'callCenter',
    'call',
    'isTransferred',
    'isIncomingTransfer',
    'callCenterUser',
    'callSid',
    'reservationSid',
    'taskSid',
    'callStartTime',
    'callerName',
    'callerPhone',
    'brandName',
    'brandCallerId',
    'brandPhone',
    'isOnCall',
    'isOnHold',
    'isOnMute',
    'isAuthenticated',
    'isRinging',
    'isCalling',
    'isParked',
    'hasMissedCall',
    'teamStats',
    'reasonTypes',
    'agentAvailability',
    'hasCallback',
    'callback',
    'isIncomingCallback',
    'chats',
    'lastChat',
    'hasChat',
    'isIncomingChat',
    'hasLastChat',
    'chatOutcomes',
    'chatReasons',
    'incomingChatName',
    'wrapUp',
    'isInWrapUp',
    'hasRequestedWrapUpExtension',
    'parentBrandName',
    'chatWrapUpQueue',
    'isVipCaller',
    'isCharterUpPlusCaller',
  ]),
}

export const callCenterMethods = mapActions('callCenter', [
  'setCall',
  'setIsTransferred',
  'setIsIncomingTransfer',
  'setCallCenterUser',
  'setToIncomingCall',
  'setToOutboundCall',
  'setToMissedCall',
  'setToOnCall',
  'setToEndCall',
  'setIsOnHold',
  'setIsOnMute',
  'setIsParked',
  'setIsAuthenticated',
  'setIsRinging',
  'setHasMissedCall',
  'setReservationSid',
  'setTaskSid',
  'setTeamStats',
  'setAgentAvailability',
  'setUserStatus',
  'setCallback',
  'setHasCallback',
  'setIsIncomingCallback',
  'setChats',
  'setIsIncomingChat',
  'addChat',
  'removeChat',
  'clearLastChat',
  'initializeChatOutcomes',
  'initializeChatReasons',
  'setIncomingChatName',
  'setToAcceptedCallback',
  'setWrapUp',
  'setIsInWrapUp',
  'setHasRequestedWrapUpExtension',
  'addToChatWrapUpQueue',
  'removeFromChatWrapUpQueue',
  'openCompleteChatDialog',
])
