import { render, staticRenderFns } from "./QuoteCreatedBySidebar.vue?vue&type=template&id=572edc85&scoped=true"
import script from "./QuoteCreatedBySidebar.vue?vue&type=script&lang=js"
export * from "./QuoteCreatedBySidebar.vue?vue&type=script&lang=js"
import style0 from "./QuoteCreatedBySidebar.vue?vue&type=style&index=0&id=572edc85&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572edc85",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VForm})
